
import es_investorTableContent from "./investorTableContent-es.json"
import en_investorTableContent from "./investorTableContent-en.json"
import fr_investorTableContent from "./investorTableContent-fr.json"
import it_investorTableContent from "./investorTableContent-it.json"
import _ from "lodash"

const byDefault = (obj) => {
  if (_.isEmpty(obj) || _.isNull(obj)) return en_investorTableContent
  else if (_.isArray(obj) && _.some(obj, (o) => _.has(o, "attributes.category"))){
    const current_categories  = _.groupBy(obj, "attributes.category")
    const merge = Object.entries(_.groupBy(en_investorTableContent, "attributes.category")).map(([key, value]) => _.get(current_categories, key, value))
    return merge.flat()
  }
  else if (_.isObject(obj) && !_.isArray(obj)) {
    const isSingleType = _.get(obj, "attributes", null)
    if (isSingleType) return { ...en_investorTableContent, ...obj }
    const result = Object.entries(en_investorTableContent).reduce((acc, [key, values]) => {
      const translation = _.get(obj, key, null)
      if (translation) {
        const newAttributes = translation.attributes
        Object.keys(newAttributes).forEach(
          _key => (newAttributes[_key] == null || (newAttributes[_key].data &&  _.isEmpty(newAttributes[_key].data))) 
            && delete newAttributes[_key]
        )
        acc[key] = {
          ...values,
          attributes: { ...en_investorTableContent[key].attributes, ...newAttributes },
        }
      } else {
        acc[key] = { ...values }
      }
      return acc
    }, {})
    return result
  }
  return obj
}

const investorTableContent = {
  en: en_investorTableContent,
  es: byDefault(es_investorTableContent),
  fr: byDefault(fr_investorTableContent),
  it: byDefault(it_investorTableContent),
}

export default investorTableContent
